<template>
  <div class="xszj_box" ref="xszj_box">
    <h1>{{ h1 }}</h1>
    <div class="top_two" v-if="h1 === '学术讲座'">
      <div v-for="(item,i) in wz_List.list" :key="i">
        <div class="one gg_sty"  @click="pushArticle_xq(item)" v-if="item.is_new == 1">
        <div class="left_time" >
          <span>{{ item.lecture_time ? item.lecture_time.slice(8,10): ''}}</span>
          <a>{{ item.lecture_time ? item.lecture_time.slice(0, 7).replace('-', '.'):''}}</a>
        </div>
        <div class="right_text">{{ item.title }}</div>
        <div class="new"></div>
      </div>
      </div>
    </div>
    <div class="nav_list" v-show="show">
      <ul>
        <li v-for="(item,i) in h1=='学术讲座'?wz_List.list.filter(itemss => itemss.is_new !== 1):wz_List.list" :key="i" @click="pushArticle_xq(item)">
         <img :src="item.is_top==1?
         'https://xq-images.obs.cn-north-4.myhuaweicloud.com/2022070618117Tx22xh':
         item.is_new==1?'https://xq-images.obs.cn-north-4.myhuaweicloud.com/2022070618150nKswsR':
         'https://xq-images.obs.cn-north-4.myhuaweicloud.com/2022070618259YxAm28'" :class="item.is_top==1||item.is_new==1?'top_top_new':'top_no_topNew'">
          <span>{{item.title}}</span>
          <a>{{item.created_at?item.created_at.slice(0,10):""}}</a>
        </li>
      </ul>
      <!-- 底部分页 -->
      <div class="pagination">
        <Pagination :total="from.total" :page="from.page" :limit="from.limit" @pageChange="pageChanges" />
      </div>
    </div>
    <nav v-html="wz_danpian" v-show="show_nav" class="navs_wzList"></nav>
  </div>
</template>

<script setup>
import Pagination from '@/components/Pagination.vue'
import * as axios from '@/api/API.js'
// import{ useRoute,useRouter,onBeforeRouteLeave,stopWatchRoute } from 'vue-router'
import{ useRoute,useRouter } from 'vue-router'
// import {ref,reactive,watch,onMounted,nextTick,onDeactivated,destroyWatch, onUnmounted} from 'vue'
import {ref,reactive,watch,onMounted, onUnmounted} from 'vue'
import base64_ from '@/hooks/useBase64text.js'
import usedownloadAttachments from '@/hooks/usedownloadAttachments.js'
const route = useRoute()
const router = useRouter()
let routeData = JSON.parse(base64_.decode(route.params.obj))
const h1 = ref(routeData.title3?routeData.title3:routeData.title2?routeData.title2:routeData.title1)
const wz_danpian = ref('')
const show = ref(true)
const show_nav = ref(true)
const wz_List = reactive({
  list: [],
  xszj_data1: {},
  xszj_data2: {},
})

const from = reactive({
  page:1,
  limit:15,
  total:0,
  type_id:'',
})
// 获取文章列表
const getwz_List = async () => {
  const {data:res} = await axios.getHttp("/api/article",from)
  if(res.code == 200){
    if(xszj_box.value){
    xszj_box.value.style.margin = '0'
    xszj_box.value.style.opacity = 1
    h1.value = routeData.title3?routeData.title3:routeData.title2?routeData.title2:routeData.title1
    }
    if(res.data.data.length == 0) { wz_danpian.value = ''}
    if(res.data.total == 1){
      h1.value = res.data.data[0].title
      show.value = false
      show_nav.value = true
      wz_danpian.value = res.data.data[0].content?res.data.data[0].content + usedownloadAttachments(res.data.data[0].pic_url):''
    }else{
      show.value = true
      show_nav.value = false
      wz_List.list = res.data.data
      from.total = res.data.total
    }
  }
}
// 把page缓存到本地，以免页面刷新回到第一页
let editFrompage = () => {
  const sessStor = JSON.parse(window.sessionStorage.getItem('page'))
  if(sessStor){
    from.page = sessStor
    getwz_List()
  }else{
    from.page = 1
    getwz_List()
  } 
}
// 分页
const pageChanges = (ee) => {
  from.page = ee
  window.sessionStorage.setItem('page',ee)
  editFrompage()
}

const xszj_box = ref(null)
onMounted(() => {
  watch(() => route.params, () => {
    if(!route.params.obj) return
    route.params.obj?routeData = JSON.parse(base64_.decode(route.params.obj)):''
    xszj_box.value.style.opacity = 0
    xszj_box.value.style.margin = '500px 0 0 0'
    from.type_id = routeData?routeData.type_id:0
    // 如果跳转到文章详情就继续保存page，否则page改为1
    if(JSON.parse(base64_.decode(route.params.obj)).article_wz_id){
    }else{
      window.sessionStorage.setItem('page',1)
    }
    // getwz_List()
    editFrompage()
    }, { deep: true})
    // 页面dom更新就把元素设置隐藏，等待数据加载完毕在显示。
    xszj_box.value.style.opacity = 0
    xszj_box.value.style.margin = '500px 0 0 0'
    from.type_id = routeData.type_id 
    editFrompage()
})
// 点击li跳转到详情
let pushArticle_xq = (item) => {
if(item.is_banner_nav == 1){
router.push({
    name:"Article_xq_time",
    params:{obj:base64_.encode(JSON.stringify({
    article_wz_id:item.id,
    title1:routeData.title1,
    type_id:routeData.type_id,
    index: routeData.index,
    id:routeData.id
    }))}
  })
}else if(item.is_banner_nav == 2){
  router.push({
    name:"Article_xq_time",
    params:{obj:base64_.encode(JSON.stringify({
    article_wz_id:item.id,
    title1:routeData.title1,
    type_id:routeData.type_id,
    index: routeData.index,
    id:routeData.id,
    isBanner_nav_id:routeData.isBanner_nav_id,
    }))}
  })
}
else{
  router.push({
    name:"Article_xq_time",
    params:{obj:base64_.encode(JSON.stringify({
    article_wz_id:item.id,
    title1:item.type_name,
    type_id:item.type_id,
    index: routeData.index,
    // id:routeData.id
    }))}
  })
}
}
onUnmounted(() => {
  if(!route.params.obj) return
  if(!JSON.parse(base64_.decode(route.params.obj)).article_wz_id){
     window.sessionStorage.setItem('page',1)
  }
})
</script>

<style lang="less" scoped>
@maxw:128rem;
.xszj_box{
  box-sizing: border-box;
  width: 100%;
  margin: 0 0 0 30px;
  font-size: 15/@maxw;
  transition: ease .8s;
>h1{
    font-size: 39/@maxw;
    font-family: fantBold;
      font-weight: 300;
    color: #174994;
    border-bottom:2/@maxw solid #174994;
    width: 100%;
    margin: 10/@maxw auto;
    padding: 0 0 10/@maxw 0;
    opacity: 1;
  }
  .top_two{
    width: 100%;
    >div{
      >.gg_sty{
      width: 100%;
      height: 140/@maxw;
      position: relative;
      background-image: url("../../../assets/ejxsjzbj2.png");
      background-size: 100%;
      background-repeat: no-repeat;
      margin: 30/@maxw 0 30/@maxw 0;
      display: flex;
      align-items: center;
      transition: ease .8s;
      cursor: pointer;
      .left_time{
        width: 100/@maxw;
        height: 100/@maxw;
        margin: 0 0 0 50/@maxw;
        border: 1px solid #fff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #fff;
        span{
          font-size: 55/@maxw;
          font-family: Source Han Sans CN-Medium;
          font-weight: 500;
        } 
        a{ 
          margin-top: -8/@maxw;
          font-size: 17/@maxw;
          font-family: Source Han Sans CN-Medium;
          font-weight: 500;
          // white-space: nowrap;
        }
      }
      .right_text{
        color: #fff;
        display: flex;
        align-items: center;
        flex: 1;
        height: 95/@maxw;
        // margin: 0 150/@maxw 0 50/@maxw;
        margin: 0 300/@maxw 0 50/@maxw;
        // overflow: hidden;
        font-size: 22/@maxw;
        font-family: Source Han Sans CN-Medium;
        font-weight: 500;
        overflow:hidden;
            text-overflow:ellipsis;
            display:-webkit-box;
            -webkit-box-orient:vertical;
            -webkit-line-clamp:3; 
      }
    }
    .new{
     position:absolute;
     width: 86/@maxw;
     height: 28/@maxw;
     right: 33/@maxw;
     top: 30/@maxw;
     background-image: url("../../../assets/ejnew2.png");
    background-size: 100% 100%;
    }
    .gg_sty:hover{
      background-image: url("../../../assets/ejxsjzbj1.png");
      .new{
        width: 86/@maxw;
        height: 28/@maxw;
        position: absolute;
        right: 33/@maxw;
        top: 30/@maxw;
        background-image: url("../../../assets/ejnew1.png");
        background-size: 100% 100%;
        z-index: 9999;
      }
    }
  }
    }
  .nav_list{
    width: 100%;
    margin: 50/@maxw 0 0 0;
    >ul{
      margin: 0;
      padding: 0;
      >li{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 50/@maxw;
        margin: 10/@maxw 0;
        border-bottom: 1/@maxw dotted #ccc;
        transition: ease .3s;
        padding: 0 0 0 10/@maxw;
        cursor: pointer;
        // >img{
        //   width: 40/@maxw;
        //   height: 45%;
        // }
        .top_top_new{
          width: 40/@maxw !important;
          height: 45%;
        }
        .top_no_topNew{
          margin: 0 0 0 10/@maxw;
          width: 11/@maxw !important;
          height: 35%;
        }
        >span{
          flex: 1;
          margin: 0 15/@maxw 0 30/@maxw;
          word-break: break-all;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
          font-size: 22/@maxw;
          font-family: Source Han Sans CN-Medium;
          font-weight: 500;
          color: #5A5A5A;
        }
        >a{
          text-align: end;
          width: 110/@maxw;
          white-space: nowrap;
        }
      }
      >li:hover{
        box-shadow: 0 0 8/@maxw rgb(90, 90, 90);
        margin-left: 3/@maxw;
      }
    }
  }
}
.pagination{
  // margin: 40/@maxw 0 0 40%; 
  display: flex;
  justify-content: center;
  margin: 6% 0 0 0; 
}
.navs_wzList{
  width: 100%;
}
@media screen and (max-width:750px) {
  @maxw_:750/100vw;
  .xszj_box{
    margin: 0 !important;
    >h1{
      font-size: 35/@maxw_ !important;
    }
    .nav_list{
       margin: 0 !important;
      >ul{
         margin: 0 !important;
        li{
          height: 100/@maxw_ !important;
          >span{
            font-size: 30/@maxw_;
          }
          .top_top_new{
          width: 8/@maxw_;
          width: 8vw !important;
          height: 30%;
        }
        .top_no_topNew{
          margin: 0 1vw;
          // width: 4/@maxw_ !important;
          width: 2vw !important;
          height: 24%;
        }
          a{
            width: 120/@maxw_;
            font-family: Source Han Sans CN-Medium;
            font-size: 20/@maxw_;
            white-space: nowrap;
          }
        }
      }
    }
    .top_two{
    width: 100%;
    >div{
      >.gg_sty{
      height: 140/@maxw_;
      margin: 30/@maxw_ 0 30/@maxw_ 0;
      background-size: 100% 100%;
      .left_time{
        width: 100/@maxw_;
        height: 100/@maxw_;
        margin: 0 0 0 50/@maxw_;
        span{
          font-size: 55/@maxw_;
        } 
        a{ 
          margin-top: -8/@maxw_;
          font-size: 17/@maxw_;
        }
      }
      .right_text{
        height: 95/@maxw_;
        margin: 0 200/@maxw_ 0 50/@maxw_;
        font-size: 22/@maxw_;
      }
    }
    .new{
     width: 86/@maxw_;
     height: 28/@maxw_;
     right: 33/@maxw_;
     top: 30/@maxw_;
    }
    .gg_sty:hover{
      .new{
        width: 86/@maxw_;
        height: 28/@maxw_;
        right: 33/@maxw_;
        top: 30/@maxw_;
      }
    }
  }
    }
  }
}
</style>